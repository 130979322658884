import api from "./api"


export default {


    pesquisaPlanoContas: async (tipo) => {
        return await api
            .get("/plano_contas/tipo/" + (tipo === "Pagar" ? "PAGAR" : "RECEBER"))
    },

    pesquisaEmpresa: async () => {
        alert
        return await api
            .get("/configuracao/")
    },



    gravar: async (conf) => {

        return await api
            .post("/configuracao/pesquisa/", conf)

    },


}

