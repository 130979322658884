import Vue from 'vue'
import VueRouter from 'vue-router'

import LoginAuth from '../views/LoginAuth.vue'
import DashboardEasy from '../DashboardEasy.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginAuth
  },
  {
    path: '/emailtokensenha',
    name: 'emailtokensenha',
    component: () => import('../views/EmailTokenSenha.vue')
  },

  {
    path: '/trocalogin',
    name: 'trocalogin',
    component: () => import('../views/TrocaSenha.vue')
  },

  {
    path: '/emailsenha',
    name: 'emailsenha',
    component: () => import('../views/EnviadoEmailRecuperacaoSenha.vue')
  },

  {
    path: '/',
    name: 'dashboard',
    component: DashboardEasy
  },

  {
    path: '/indexparceironegocio',
    name: 'indexparceironegocio',
    component: () => import('../views/cadastro/parceironegocios/indexParceiroNegocio.vue')
  },
  {
    path: '/parceironegocio',
    name: 'parceironegocio',
    component: () => import('../views/cadastro/parceironegocios/parceiroNegocioView.vue')
  },
  {
    path: '/parceironegocio/:id',
    name: 'parceironegocioedit',
    component: () => import('../views/cadastro/parceironegocios/parceiroNegocioView.vue')
  },
  {
    path: '/atividade',
    name: 'atividade',
    component: () => import('../views/cadastro/parceironegocios/atividades/atividadeView.vue')
  },
  {
    path: '/regiao',
    name: 'regiao',
    component: () => import('../views/cadastro/parceironegocios/regiao/regiaoView.vue')
  },

  {
    path: '/condicao',
    name: 'condicao',
    component: () => import('../views/cadastro/condicao/condicaoPagamento.vue')
  },

  {
    path: '/indexproduto',
    name: 'indexproduto',
    component: () => import('../views/cadastro/produto/indexProduto.vue')
  },
  {
    path: '/produto',
    name: 'produto',
    component: () => import('../views/cadastro/produto/produtoView.vue')
  },
  {
    path: '/produto/:id',
    name: 'produtoedit',
    component: () => import('../views/cadastro/produto/produtoView.vue')
  },

  {
    path: '/observacaolei',
    name: 'observacaolei',
    component: () => import('../views/cadastro/produto/obs/ObsLeiView.vue')
  },

  {
    path: '/listapreco',
    name: 'listapreco',
    component: () => import('../views/cadastro/produto/ListaPreco/listaPreco.vue')
  },

  {
    path: '/indexfigurafiscal',
    name: 'indexfigurafiscal',
    component: () => import('../views/cadastro/produto/figurafiscal/IndexFiguraFiscal.vue')
  },

  {
    path: '/figurafiscal',
    name: 'figurafiscal',
    component: () => import('../views/cadastro/produto/figurafiscal/FiguraFiscal.vue')
  },

  {
    path: '/figurafiscal/:id',
    name: 'figurafiscaledit',
    component: () => import('../views/cadastro/produto/figurafiscal/FiguraFiscal.vue')
  },
  
  {
    path: '/mercadologico/',
    name: 'mercadologico',
    component: () => import('../views/cadastro/produto/mercadologico/mercadologicoView.vue')
  },

  {
    path: '/loja',
    name: 'loja',
    component: () => import('../views/configuracao/lojaIndex.vue')
  },

  {
    path: '/configuracao',
    name: 'configuracao',
    component: () => import('../views/configuracao/configuracaoIndex.vue')
  },

  {
    path: '/vendedor',
    name: 'vendedor',
    component: () => import('../views/configuracao/vendedorIndex.vue')
  },

  
  {
    path: '/localestoque',
    name: 'localestoque',
    component: () => import('../views/estoque/localEstoque.vue')
  },

  {
    path: '/banco',
    name: 'banco',
    component: () => import('../views/financeiro/IndexBanco.vue')
  },

  {
    path: '/planodecontas',
    name: 'planodecontas',
    component: () => import('../views/financeiro/IndexPlanoContas.vue')
  },
  {
    path: '/contas',
    name: 'contas',
    component: () => import('../views/financeiro/ContasPagarReceber.vue')
  },
 
  {
   path: '/conciliacaobancaria',
   name: 'conciliacaobancaria',
   component: () => import('../views/financeiro/ConciliacaoBancaria.vue')
  },

  {
    path: '/inventario',
    name: 'inventario',
    component: () => import('../views/estoque/inventarioEstoque')
  },

  {
    path: '/transferenciaestoque',
    name: 'transferenciaestoque',
    component: () => import('../views/estoque/TransferenciaEstoque.vue')
  },

  {
    path: '/entradamercadoria',
    name: 'entradamercadoria',
    component: () => import('../views/estoque/IndexEntradaNotaFiscal.vue')
  },

  {
    path: '/nfentrada',
    name: 'nfentrada',
    component: () => import('../views/estoque/EntradaNota.vue')
  },

 {
  path: '/nfentrada/:id',
  name: 'nfentradaedit',
  component: () => import('../views/estoque/EntradaNota.vue')
  },

  {
    path: '/pedido',
    name: 'pedido',
    component: () => import('../views/vendas/IndexPedido.vue')
  },
  {
    path: '/pedidovenda',
    name: 'pedidovenda',
    component: () => import('../views/vendas/PedidoVenda.vue')
  },

  {
    path: '/pedidovenda/:id',
    name: 'pedidovendaedit',
    component: () => import('../views/vendas/PedidoVenda.vue')
  },

  {
    path: '/nota',
    name: 'nota',
    component: () => import('../views/vendas/IndexNota.vue')
  },

 {
   path: '/notafiscal',
   name: 'notafiscal',
   component: () => import('../views/vendas/NotaFiscal.vue')
 },

 {
   path: '/notafiscal/:id',
   name: 'notafiscaledit',
    component: () => import('../views/vendas/NotaFiscal.vue')
 },

  {
    path: '/notafiscal/ped/:id',
    name: 'notafiscalpedidoedit',
    component: () => import('../views/vendas/NotaFiscal.vue')
  },

  {
    path: '/relatoriocadastro',
    name: 'relatoriocadastro',
    component: () => import('../views/relatorio/relCadastro.vue')
  },
  {
    path: '/relatorioestoque',
    name: 'relatorioestoque',
    component: () => import('../views/relatorio/relEstoque')
  },
   {
    path: '/relVendas',
    name: 'relVendas',
    component: () => import('../views/relatorio/relVenda')
  },



]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
