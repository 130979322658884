import axios from "axios";

const api = axios.create({
    withCredentials: true,
    //baseURL: process.env.VUE_APP_BACKEND_URL,
    baseURL: "https://flexteasy.com:8000",
    headers: {
        "Content-type": "application/json",
        "Accept": "application/json",
    }
});


api.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (!(error.config.url === "/security/login/"
        || error.config.url === "/security/")
        && error.response.status === 401) {

        this.$toast.error("Atenção: Informar um E-mail válido."); ("Você não está autenticado!");
        window.location.href = "/";
    }
    return Promise.reject(error);
});

export default api



