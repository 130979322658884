<template>
  <v-app>
    <Navbar />
    <v-main class="ma-4">
      <router-view />
    </v-main>
  </v-app>
</template>


<script>
import Navbar from "./components/menu/MenuNavBar";

export default {
  name: "App",
  components: {
    Navbar,
  },
  data: () => ({
    //
  }),
};
</script>
