<template>
  <div>
    
  
  </div>
</template>
  
<script>
import userlogado from "./services/usuarioService";

export default {
  name: "dashBoard",
  data: () => ({
    step: 1,
    show1: false,
    show2: false,
    valid: true,
    email: "edelsio@gmail.com",
    password: "123",
    loginDisabled: false,

    rules: {
      min4Chars: (value) => value.length >= 3 || "Min. 3 characters",
      required: (value) => !!value || "Este campo é obrigatório.",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\'.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Email Inválido.";
      },
    },
  }),

  beforeMount() {
      userlogado
      .logado()
      .then(() => {
       this.$store.commit("getMenu", true);
      })
      .catch(() => {
        this.$store.commit("getMenu", false);
        this.$router.push({ name: "login" });
      });
  },
};
</script>
