<template>
    <div id="app"  >
      <nav v-if="notIsLoginPage">
        <v-app-bar color="#3b5998" dark app>
          <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
          <v-spacer></v-spacer>
          <div>
            <span style="font-size: small; font-style: italic">{{
              nomeUsuario
            }}</span>
          </div>
  
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn text v-on="on">
                <v-icon left>mdi-account</v-icon>
                <span></span>
              </v-btn>
            </template>
            <v-list flat>
              <v-list-item v-for="link in listUsario" :key="link.text">
                <v-list-item-title>{{ link.text }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
  
          <div>
            <v-btn text @click="sair">
              <v-icon right>exit_to_app</v-icon>
              <span class="mx-2">Sair</span>
            </v-btn>
          </div>
        </v-app-bar>
        <v-navigation-drawer v-model="drawer" dark app color="#3b5998">
          <v-layout column align-center>
        
            <v-avatar size="60">
              <v-img :src="require('@/assets/avatarLogin.png')" />
            </v-avatar>

           
          </v-layout>
  
          <v-list flat>
            <div v-for="(link, x) in links" :key="x">
              <v-list-item
                v-if="!link.subLinks"
                :key="x"
                router
                :to="link.route"
                active-class="border"
              >
                <v-list-item-action>
                  <v-icon>{{ link.icon }}</v-icon>
                </v-list-item-action>
  
                <v-list-item-content>
                  <v-list-item-title>{{ link.text }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
  
              <v-list-group
                v-else
                :key="x"
                router
                no-action
                :prepend-icon="link.icon"
                :value="false"
              >
                <template v-slot:activator>
                  <v-list-item-title>{{ link.text }}</v-list-item-title>
                </template>
  
                <v-list-item
                  class="pl-5"
                  v-for="sublink in link.subLinks"
                  :to="sublink.to"
                  :key="sublink.text"
                  router
                  active-class="border"
                >
                  <v-list-item-action
                    class="pl-2 mr-2"
                    v-if="sublink.text !== 'Nota Fiscal'"
                  >
                    <v-icon>{{ sublink.icon }}</v-icon>
                  </v-list-item-action>
  
                  <v-list-item-action class="pl-2 mr-2" v-else>
                    <v-img
                      height="24px"
                      width="24px"
                      src="../../assets/NFe.png"
                      left
                      class="pe-0"
                    ></v-img>
                  </v-list-item-action>
  
                  <v-list-item-content class="pl-2 mr-2">
                    <v-list-item-title>{{ sublink.text }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </div>
          </v-list>
        </v-navigation-drawer>
      </nav>
    </div>
  </template>
    
  <script>
  import userLogado from "../../services/usuarioService";
  
  export default {
    name: "navBar",
    data: () => ({
      drawer: true,
     
      step: 1,
      show1: false,
      show2: false,
      valid: true,
      nomeUsuario: localStorage.nomeUsuario,
      listUsario: [
        { icon: "dashboard", text: "Alterar Senha", action: "alterSenha" },
      ],
  
      links: [
        { icon: "mdi-home-analytics", text: "Home", route: "/dashboard" },
        {
          icon: "folder",
          text: "Cadastro",
          subLinks: [
            {
              text: "Parceiro Negócio",
              to: "/indexparceironegocio",
              icon: "mdi-account-group",
            },
            {
              text: "Produto",
              to: "/indexproduto",
              icon: "mdi-package-variant",
            },
            {
              text: "Condição Pagamento",
              to: "/condicao",
              icon: "mdi-window-restore",
            },
          ],
        },
        {
          icon: "mdi-human-dolly",
          text: "Suprimentos",
          subLinks: [
            {
              text: "Entrada Mercadoria",
              to: "/entradamercadoria",
              icon: "mdi-book-open-outline",
            },
            {
              text: "Avulso",
              to: "/estoqueavulso",
              icon: "dynamic_feed",
            },
            {
              text: "Transferência",
              to: "/transferenciaestoque",
              icon: "dynamic_feed",
            },
            {
              text: "Inventário",
              to: "/inventario",
              icon: "mdi-dolly",
            },
          ],
        },
        {
          icon: "mdi-cart-variant", //"mdi-cash-register",
          text: "Vendas",
          subLinks: [
            {
              text: "Pedidos de Venda",
              to: "/pedido",
              icon: "mdi-file-document",
            },
            {
              text: "Nota Fiscal",
              to: "/nota",
              icon: "request_quote",
            },
  
            {
              text: "CF-e",
              to: "/nota",
              icon: "request_quote",
            },
          ],
        },
  
        {
          icon: "trending_up",
          text: "Financeiro",
          subLinks: [
            {
              text: "Pagar/Receber",
              to: "/contas",
              icon: "mdi-cash",
            },
            {
              text: "Extrato",
              to: "/conciliacaobancaria",
              icon: "monetization_on",
            },
            {
              text: "Plano Contas",
              to: "/planodecontas",
              icon: "mdi-sitemap",
            },
            {
              text: "Banco",
              to: "/banco",
              icon: "mdi-cash-register",
            },
          ],
        },
  
        {
          icon: "mdi-printer",
          text: "Relatório",
          subLinks: [
            {
              text: "Cadastro",
              to: "/relatoriocadastro",
              icon: "mdi-account-tie",
            },
            {
              text: "Estoque",
              to: "/relatorioestoque",
              icon: "local_printshop",
            },
            {
              text: "Vendas",
              to: "/relvendas",
              icon: "mdi-account-tie",
            },
            {
              text: "Financeiro",
              to: "/relfinanceiro",
              icon: "mdi-account-tie",
            },
          ],
        },
  
        {
          icon: "mdi-cogs",
          text: "Utilitário",
          subLinks: [
            {
              text: "Vendedor",
              to: "/vendedor",
              icon: "mdi-account-tie",
            },
            {
              text: "Loja",
              to: "/loja",
              icon: "mdi-alpha-l-circle-outline",
            },
            {
              text: "Local Estoque",
              to: "/localestoque",
              icon: "mdi-database",
            },
            {
              text: "Configuração",
              to: "/configuracao",
              icon: "mdi-cog-outline",
            },
          ],
        },
      ],
    }),
    methods: {
    sair() {
      userLogado
        .logOut()
        .then((res) => {
          console.log(res);
          window.location.href = "/";
        })
        .catch((error) => {
          console.log(error);
          window.location.href = "/";
        });
    },
  },
  
    computed: {
      notIsLoginPage() {
        return (
          this.$route.name !== "login" &&
          this.$route.name !== "trocalogin" &&
          this.$route.name !== "emailtokensenha" &&
          this.$route.name !== "emailsenha"
        );
      },
    },
  };
  </script>
  
    <style scoped>
  .border {
    border-left: 4px solid #0ba518;
  }
  
  .max-v-list-height {
    max-height: 45px;
  }
  
  .iconespace {
    padding-left: 0x;
  }
  </style>
    