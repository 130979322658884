import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store' 

import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";

const options = {
  position: POSITION.TOP_RIGHT,
  timeout: 2300,
}
Vue.use(Toast, options);

Vue.config.productionTip = false

import vuetify from '@/plugins/vuetify' // path to vuetify export

import VueMask from 'v-mask'
Vue.use(VueMask);

new Vue({
  router,
  store,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')
