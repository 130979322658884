<template>
  <div class="text-center" v-show="isloading">
    <v-progress-circular
      :size="50"
      color="primary"
      indeterminate
    ></v-progress-circular>
    <span style="font-size: 60%; background: inherit"
      ><strong class="pa-2">Aguarde...</strong></span
    >
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
    },
  },
  data: () => ({}),

  computed: {
    isloading: {
      get() {
        return this.visible;
      },
    },
  },
};
</script>
