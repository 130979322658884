import api from "../services/api"

export default {

    logado: () => {
        return api.get("/security");
    },

    logar: (email, password) => {
        
        return api
            .post("/security/login/", {
                email: email,
                senha: password,
            })
    },

    logOut: () => {
        return api.delete("/security/logout/");
    },

}


/*
export function isLogado() {
    try {
        api
            .get("/security/")
            .then(() => {
                return true
            })
            .catch(() => {
                return false
            });
    } catch (error) {
        return false
    }
}

/*
export function consultaUsuario(email, password) {
    try {
        api
            .post("/security/login/", {
                email: email,
                senha: password,
            })
            .then((res) => {
                if (res.status === 200) {
                    localStorage.configuracaoEstoqueId = "1";
                    localStorage.configuracaoLojaId = "1";
                    localStorage.configuracaoLojaNome = "Loja1";
                    localStorage.planoContasEntradaId = "7";
                    localStorage.pais = 30;
                    localStorage.nomePais = "BRASIL";
                    localStorage.consultaDiasFinanceiro = 30;
                    localStorage.imprimirReciboPagamento = "1";
                    this.$router.push({ name: "dashboard" });
                }
                return true
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    this.snackbars.appear = true;
                    this.snackbars.text = "Atenção: Usuário sem permissão.";
                } else {
                    console.log("a" + error);
                }
            });

    } catch (error) {
        return false
    }

}

*/



