<template>
  <v-app>
    <v-main>
      <v-container fill-height fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="8">
            <v-card class="elevation-12">
              <v-window v-model="step">
                <v-window-item :value="1">
                  <v-row class="pb-2">
                    <v-col cols="12" md="8">
                      <v-card-text class="mt-12">
                        <h1
                          class="text-center display-2 teal-text text--accent-3"
                        >
                          FlextEasy
                        </h1>

                        <h4 class="text-center mlt-4">
                          Gestão na medida Certa.
                        </h4>
                        <v-form ref="form" v-model="valid">
                          <v-text-field
                            label="E-mail"
                            name="email"
                            prepend-icon="email"
                            type="text"
                            color="teal accent-3"
                            v-model="email"
                            :rules="[rules.required, rules.email]"
                            ref="email"
                          />
                          <v-text-field
                            id="password"
                            label="Senha"
                            name="password"
                            prepend-icon="lock"
                            color="teal accent-3"
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show1 ? 'text' : 'password'"
                            @click:append="show1 = !show1"
                            v-model="password"
                            :rules="[rules.required, rules.min4Chars]"
                            autocomplete="on"
                          />
                        </v-form>

                        <span
                          class="text-left mt-3"
                          @click="emailtokensenha"
                          style="cursor: pointer; color: Blue"
                          >Esqueceu sua senha?</span
                        >
                      </v-card-text>
                      <loading :visible="visible"></loading>
                      <div class="text-center mt-3">
                        <v-btn
                          :disabled="loginDisabled"
                          rounded
                          color="#1976D4"
                          class="mb-1"
                          dark
                          @click="login"
                        >
                          Entrar</v-btn
                        >
                      </div>
                    </v-col>
                    <v-col cols="12" md="4" style="background-color: #1976d4">
                      <v-card-text class="white--text mt-12">
                        <h1 class="text-center display-1">Olá</h1>
                        <h5 class="text-center">Não possue Cadastro?</h5>
                      </v-card-text>
                      <br />

                      <div class="text-center">
                        <v-btn rounded outlined="" dark @click="step++"
                          >Inscreva-se</v-btn
                        >
                        <br /><br /><br />
                        <v-avatar size="70">
                          <v-img :src="require('@/assets/avatarLogin.png')" />
                        </v-avatar>
                      </div>
                    </v-col>
                  </v-row>
                </v-window-item>

                <v-window-item :value="2" style="min-height: 426px">
                  <v-row class="fill-height">
                    <v-col cols="12" md="4" style="background-color: #1976d4">
                      <v-card-text class="white--text mt-12">
                        <h1 class="text-center display-1">Bem-Vindo</h1>
                        <h5 class="text-center">
                          Software de Gestão Empresarial
                        </h5>
                      </v-card-text>
                      <div class="text-center">
                        <br />
                        <h5 class="text-center white--text" dark>
                          Já tem cadastro?
                        </h5>

                        <v-btn
                          rounded
                          outlined
                          dark
                          class="mt-1"
                          @click="step--"
                          >Entre</v-btn
                        >
                        <br />
                        <br />
                        <br />
                        <v-avatar size="70">
                          <v-img :src="require('@/assets/avatarLogin.png')" />
                        </v-avatar>
                      </div>
                    </v-col>
                    <v-col cols="12" md="8">
                      <v-card-text class="mt-12">
                        <h1
                          class="text-center display-2"
                          style="color: #1976d4"
                        >
                          Criar Conta
                        </h1>

                        <h4 class="text-center mt-4">
                          Entre com as Informações
                        </h4>

                        <v-form>
                          <v-text-field
                            label="Nome *"
                            name="Name"
                            prepend-icon="person"
                            type="text"
                            color="#1976D4"
                            class="ma-0 pa-0"
                          />

                          <v-text-field
                            label="E-mail *"
                            name="Email"
                            prepend-icon="email"
                            type="text"
                            color="#1976D4"
                            class="ma-0 pa-0"
                          />

                          <v-text-field
                            label="Senha *"
                            name="Senha"
                            prepend-icon="lock"
                            color="#1976D4"
                            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show2 ? 'text' : 'password'"
                            @click:append="show2 = !show2"
                            autocomplete="on"
                            class="ma-0 pa-0"
                          />

                          <v-text-field
                            label="Confirmar Senha *"
                            name="Confirmar_Senha"
                            prepend-icon="lock"
                            class="ma-0 pa-0"
                            color="#1976D4"
                            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show2 ? 'text' : 'password'"
                            @click:append="show2 = !show2"
                          />
                        </v-form>
                      </v-card-text>
                      <div class="text-center mt-0">
                        <v-btn rounded color="#1976d4" dark depressed
                          >Inscrição</v-btn
                        >
                      </div>
                    </v-col>
                  </v-row>
                </v-window-item>
              </v-window>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>
  
  
<script>
//import api from "../../services/api";

//import isEmailValido from "../../util/validadorCampos";

import userlogado from "../services/usuarioService";
import configuracaoService from "../services/configuracaoService";
import loading from "../components/isLoading";

export default {
  name: "loginPage",
  components: {
    loading,
  },
  data: () => ({
    step: 1,
    show1: false,
    show2: false,
    valid: true,
    email: "jeferson.lucio@gmail.com",
    password: "123",
    loginDisabled: false,
    visible: false,
    rules: {
      min4Chars: (value) => value.length >= 3 || "Min. 3 characters",
      required: (value) => !!value || "Este campo é obrigatório.",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\'.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Email Inválido.";
      },
    },
  }),

  // beforeMount() {
  //  userlogado
  //    .logado()
  //    .then(() => {
  //      this.$router.push({ name: "dashboard" });
  //    })
  //    .catch(() => {
  //     this.loginDisabled = false;
  //    });
  // console.log(solLamento.logado.d;
  // if (isLoagado().usuarioService.logadodo) {
  // this.loginDisabled = true;
  //  this.$router.push({ name: "" });
  //   this.$toast.success("Atenção: Inforasdasdasdasdasmar um E-mail válido.");
  // } else {
  //   this.$toast.error("Atenção: Informar um E-mail válido.");
  //  this.loginDisabled = false;
  // }
  //},

  methods: {
    doSomethingCool() {
      alert(1);
    },

    login() {
      
      if (!this.$refs.form.validate()) {
        return;
      }

      // this.$toast.clear();

      // this.$router.push({ name: "dashboard" });
      /*
      api
        .post("/security/login/", {
          email: this.email,
          senha: this.password,
        })
        .then((res) => {
          console.log(res);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$toast.success(
              "Atenção: Credenciais Inválidas. Verifique E-mail e/ou Senha e tente novamente."
            );
          } else {
            this.$toast.error("Atenção, erro na tentativa de logar");
          }
        });

      */
      
      this.visible = true;
      this.$toast.clear();
      userlogado
        .logar(this.email, this.password)
        .then((resposta) => {
          configuracaoService.pesquisaEmpresa().then((res) => {
            console.log(res.data);
            localStorage.pais = res.data.paisId;

            localStorage.IncluiFreteBaseDeCalculoImpostos =
              res.data.incluiBcFrete;
            localStorage.IncluiSeguroBaseCalculoImpostos =
              res.data.incluiBcSeguro;
            localStorage.IncluiOutrasDespesasBaseCalculoImpostos =
              res.data.incluiBcOutrasDespesa;
            localStorage.DeduzDescontosBaseDeCalculoImpostos =
              res.data.incluiBcDesconto;

            localStorage.cidadeIdPadrao = res.data.cidadeId;
            localStorage.ufIdPadrao = res.data.ufId;

            localStorage.vendaPlanoContasId = res.data.vendaPlanoContasId;

            localStorage.planoContasEntradaId =
              res.data.mercadoriaPlanoContasId;

            localStorage.naturezaOperacao = res.data.naturezaOperacao;
            localStorage.figuraFiscalId = res.data.figuraFiscalId;

            localStorage.imprimirReciboPagarReceber =
              res.data.imprimirReciboPagarReceber === true ? 1 : 0;

            localStorage.nomeUsuario = resposta.data.usuario.nome;

            localStorage.regimeLoja = res.data.regimeLoja;
            localStorage.lojaId = res.data.lojaId;
            localStorage.origemUF = res.data.origemUF;
            localStorage.nomeLoja = res.data.nomeLoja;

            console.log("sdasdasd");
            console.log(localStorage);
          });
          this.visible = false;
          this.$router.push({ name: "dashboard" });
         
          //this.$store.dispatch('visualizarMenu', true)

          this.$store.commit("getMenu", true);

        })
        .catch(() => {
          this.$toast.error("Usuário sem Permissão de Acesso!");
          this.loginDisabled = false;
        })
        .finally(() => {
          this.visible = false;
        });
    },

    emailtokensenha() {
      this.$router.push({ name: "emailtokensenha" });

      //  if (isEmailValido(this.email)) {
      // this.$toast.error("Atenção: Informar um E-mail válido.");
      // return;
      // }
    },
  },
};
</script>
      
      
  